<template>
  <div class="case-conent">
    <div class="hd">
      <div class="back"><el-button class="back-btn" @click="back()" icon="el-icon-back" type="primary" size="mini"  plain round>返回</el-button></div>
      知识内容测评
    </div>
    <!--学历案日志列表-->
    <div class="table-content">
      <div class="stas">
        <div class="flf">图书名：{{bookName}}</div>
        <div class="frg">
          <el-select v-model.trim="fromData.type" @change="stateChang" @clear="stateClear()" clearable placeholder="掌握情况选择">
            <el-option
                    v-for="item in stateList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
            </el-option>
          </el-select>
        </div>

      </div>
      <div class="table2">
        <el-table
            border
            :header-cell-style="{background:'#D1EEFE',color:'#333'}"
            :data="listData"
        >
<!--          <el-table-column prop="sort" label="序号"  header-align="center" width="50"></el-table-column>-->
          <el-table-column prop="questionNo" label="题号"  header-align="center" width="80"></el-table-column>
          <el-table-column prop="knowledge" label="知识内容" header-align="center"></el-table-column>
<!--          <el-table-column prop="subjectName" label="学科" header-align="center" width="80"></el-table-column>-->
          <el-table-column prop="isRight" label="掌握情况" width="200" header-align="center">
            <template #default="scope">
              <span v-if="scope.row.isRight=='掌握较好'">{{scope.row.isRight}}</span>
              <span v-if="scope.row.isRight=='未掌握'" style="color:#FF4D00">{{scope.row.isRight}}</span>
              <span v-if="scope.row.isRight=='基本掌握'">{{scope.row.isRight}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page">
        <div class="pag-box" v-if="listData !=0">
          <div class="total-box">
            <span class="total_pag">共<em> {{ total }} </em>条</span>
          </div>
          <el-pagination
              :current-page="currentPage"
              background
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              v-if="total != 0"
          >
            <span class="pag_sise">每页显示</span>
          </el-pagination>
        </div>
      </div>

    </div>
  </div>
</template>


<script>
import {qfindScreenSubjectObj,queryContentBookListObj,tKnowledgeListObj} from '@/api/evaluation'
export default {
  name: '',
  data() {
    return {
      clickFlag:1,
      SubjectData: [],
      pageSize:null,
      pageCurrent:null,
      stateList: [
        {
          name: '未掌握',
          id: 1
        },
        {
          name: '基本掌握',
          id: 2
        },
        {
          name: '掌握较好',
          id: 3
        }
      ],
      fromData: {
        type: '',
      },
      total:null,
      evaluation:{
        subject:''
      },
      listData:[

      ],
      bookName:''

    }
  },
  created() {
    this.EducationCase = this.$route.query.EducationCase;
    this.testPaperId=this.$route.query.testPaperId;
    this.bookName=this.$route.query.bookName;
    this.stu = this.$route.query.stu;
    this.findScreenSubject()
    this.queryContentBookList()
  },
  watch: {
    // 监视搜索词变化
    $route(){
      this.clickFlag= this.$route.query.clickFlag
    },

  },
  methods: {
    //返回
    back() {
      this.$router.go(-1);
    },
    // 状态
    stateChang($event){
      this.fromData.type = $event
      this.queryContentBookList()
      console.log($event,'99944949')
    },
    // 清除图书名
    stateClear() {
      this.fromData.type = ''
    },
    //查询筛选的学科列表
    findScreenSubject() {
      qfindScreenSubjectObj().then((res) => { // eslint-disable-line no-unused-vars
        this.SubjectData = res.data
      })
    },
    // 学科
    subjectChange($event){
      sessionStorage.setItem('subjectId',$event)
      this.queryContentBookList()
    },
    // 清除学科
    subjectClear() {
      this.evaluation.subject = ''
      sessionStorage.removeItem('subjectId');
      this.queryContentBookList()
    },



    //查询列表
    queryContentBookList() {
      let obj = {
        paperId:Number(this.testPaperId),
        type:Number(this.fromData.type),
        pageCurrent: this.currentPage,//初始页
        pageSize: this.pageSize,//每页的数据
      }
      tKnowledgeListObj(obj).then((res) => { // eslint-disable-line no-unused-vars
        if(res.code==200) {
          this.listData = res.data.data.records
          this.pageSize = res.data.data.size;
          this.currentPage = res.data.data.current;
          this.total = res.data.data.total
        } else {
          this.listData =null
          this.pageSize = null;
          this.currentPage = null;
          this.total = null
        }

      })
    },
    //充值订单列表分页
    handleSizeChange(val) {
      this.pagesize = val;
      this.queryContentBookList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      // this.setContextData("currentPage8", this.currentPage);
      // 刷新页面内容
      this.queryContentBookList();
    },
    // 去知识点
    tosKnowledgeContent(scope) {
      this.$router.push({
        path: "/mainb/tentKnowledgeList",
        query: {
          EducationCase:this.EducationCase,
          bookId:scope.row.id,
          clickFlag:2,
          stu:this.stu
        }
      });
    },


  },
  components: {

  }
}
</script>
<style lang="scss" scoped>
.case-conent {
  margin: 20px auto;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 0.9;

  .hd {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    background: #37AEFF;
    text-align: center;
    color: #fff;
    opacity: 1;
    position: relative;
    .back {
        position: absolute;
        justify-content: flex-start;
        padding-left: 20px;
        box-sizing: border-box;
        .back-btn {
            border: 1px solid #c4e7ff;padding: 0px 20px; height:32px;line-height: 32px; background-color: #37AEFF; color:#c4e7ff
        }
    }
  }

  .table-content {
    width: 96%;
    margin: 10px auto;
    .stas {
      width: 100%;
      padding: 10px 0;
      box-sizing: border-box;
      display: flex;
      .flf {
        width: 1600px;
        line-height: 40px;
        justify-content: flex-start;

      }
      .frg {
        width: 200px;
        text-align: right;
        justify-content: flex-end;

      }
    }
    .table2 {
      height: 80%;
    }
    .page {
      height: 40px;
    }
  }

  .pag-box {
    width: 100%;
    padding-top: 10px;
    box-sizing: content-box;
    justify-content: center;
    display: flex;

    .total-box {
      float: left;
      line-height: 32px;
      margin-right: 10px;
      justify-content: flex-end;
      display: flex;

      .el-pagination {
        white-space: nowrap;
        padding: 2px 5px 2px 0px;
        color: #303133;
        font-weight: 700;

        .el-pager li {
          background: #ccc;
          -webkit-box-sizing: border-box;
        }

        .el-pager li .active {
          background-color: #FF6820;
          color: #FFF;
        }
      }


    }

  }
}
.dialogOrder {
  width: 100%;
  margin: auto auto 20px auto;
  .dialogBody {
    width: 100%;
    line-height: 30px;
    display: flex;
    .lf {
      width: 50%;
      color: #666;
      text-align: right;
    }
    .rg {
      width: 50%;
      color: #000;
      text-align: left;
    }
  }
}
.dialogFooter {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #f2f2f2;
  display: flex;
  justify-content: center;
}
</style>
